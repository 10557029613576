import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { TextField, Button, Card } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import MaterialUITheme from '../../config/material-ui-theme';
import Dataconnector from '../../assets/images/logos/main_inverse.png';
import useScript from '../../config/useScript';
import { UserAPIService } from '../../config/api-service';
import { handleError } from '../../config/api-response';
import { useAppContext } from '../../context';

const { REACT_APP_URL } = process.env;

const FreeTrialLogin = () => {
    const { state } = useLocation()
    const [EmailTXT, setEmail] = useState(state?.email ? state?.email : "");
    const [PasswordHASH, setPassword] = useState('');
    const [loginErrorMessage, setLoginErrorMessage] = useState("");

    const history = useHistory()

    const theme = useTheme()
    const sm = useMediaQuery(theme.breakpoints.up('sm'))

    const { ip } = useAppContext()
    useScript(ip)

    const handleSubmit = (event) => {
        event.preventDefault();

        UserAPIService.login({ EmailTXT, PasswordHASH })
            .then(json => {
                if (json.status === 200) {
                    if (json?.data?.temporaryPassword) history.push({ pathname: '/change-password', state: { EmailTXT, PasswordHASH } })
                    else window.location.assign(REACT_APP_URL)
                } else {
                    if (json.status === 404) {
                        throw Error("Looks like there has been an error on our end. Sorry! (Error code 404)")
                    } else if (json.status === 401) {
                    }
                }
            })
            .catch(err => handleError(err, "Form", setLoginErrorMessage));
    }

    return (
        <MaterialUITheme>
            <div className="main-container margin-5" style={{ margin: sm ? "50px 10px" : "30px 10px" }}>
                <Card raised={true} className='login'>
                    <div>
                        <img src={Dataconnector} alt="Dataconnector" width={120} />
                        <p style={{ fontSize: sm ? "26px" : "20px", margin: "0 0 15px 0" }}>Welcome to Data Connector</p>
                        <p style={{ fontSize: sm ? "26px" : "20px", margin: 0 }}>Please check your email<br />for your authorization code</p>
                        <p style={{ fontSize: sm ? "14px" : "13px", margin: 0 }}>(It may be in your spam folder)</p>
                    </div>
                    <p style={{ fontSize: "28px", fontWeight: 600, margin: "15px 0", color: "#757575" }}>Login</p>
                    <form noValidate onSubmit={handleSubmit} >
                        <div className="margin-2 not-sides">
                            <TextField
                                size="small"
                                className="field"
                                id="username"
                                name="username"
                                placeholder="Email"
                                variant="outlined"
                                type="text"
                                value={EmailTXT}
                                onChange={e => setEmail(e.target.value)} />
                        </div>
                        <div className="not-sides">
                            <TextField
                                size="small"
                                className="field"
                                id="password"
                                name="password"
                                placeholder="Enter Authorization Code"
                                variant="outlined"
                                type="password"
                                value={PasswordHASH}
                                onChange={i => setPassword(i.target.value)} />
                        </div>
                        <div className="error text margin-1">{loginErrorMessage}</div>
                        <div className="row" style={{ marginTop: "40px" }}>
                            <Button fullWidth={true} type="submit" variant="contained" className="btn btn-primary submit align-center">Submit</Button>
                        </div>
                    </form>
                </Card>
            </div>

        </MaterialUITheme>
    );
};

export default FreeTrialLogin;