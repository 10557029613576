import { useRef } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import '../../config/style.css';
// import DataConnectorIcon from '../../assets/images/logos/main.png';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { useEffect } from 'react';
import { Cookies } from "react-cookie-consent";
import DataConnectorIcon from '../../assets/images/logos/main_inverse.png';
import { UserAPIService } from '../../config/api-service';
import useCaptureEvent from '../../config/useCaptureEvent';
import { useAppContext } from '../../context';
import { ButtonFilled } from '../utils/button';

const { REACT_APP_URL_ANONYMOUS } = process.env;

export default function TopNavBar() {
    function openDrawer(open = true) {
        var x = document.getElementById("myTopnav");
        if (open && x.className === "topnav") {
            x.className += " responsive change";
        } else {
            x.className = "topnav";
        }
    }

    const { ip } = useAppContext();
    const captureEvent = useCaptureEvent();

    const useAvoidDidMountEffect = (func, deps) => {
        const didMount = useRef(false)

        useEffect(() => {
            if (didMount.current) func()
            else didMount.current = true
        }, deps)
    }

    const handleSubmit = async () => {
        if (!parseInt(Cookies.get('anonymous'))) {
            const { data } = await UserAPIService.anonymousRegister({ timezone: Intl.DateTimeFormat().resolvedOptions().timeZone });
            if (data.success) {
                window.location.assign(REACT_APP_URL_ANONYMOUS);
                captureEvent(ip, "Try Now", "Success");
            }
            else{
                captureEvent(ip, "Try Now", data?.message);
            }
        }
        window.location.assign(REACT_APP_URL_ANONYMOUS);
        captureEvent(ip, "Try Now", "Clicked");
    }

    // const handleSubmit = async () => {
    //     const { data } = await UserAPIService.anonymousRegister({ timezone: Intl.DateTimeFormat().resolvedOptions().timeZone })
    //     if (data.success) {
    //         window.location.assign(REACT_APP_URL_ANONYMOUS);
    //     }
    // }

    const location = useLocation()
    useAvoidDidMountEffect(() => {
        openDrawer(false)
    }, [location])

    return (
        <>
            <nav className="topnav" id="myTopnav">
                <div style={{ display: "flex", margin: 0, alignItems: "center" }}>
                    <img src={DataConnectorIcon} className="nav-logo-img" alt="Data Connector" />
                    <h1 className='topNavHeader'>data connector</h1>
                </div>
                {/* <div className="is-active">{isActive}</div> */}
                <ul className="nav-links">
                    <li>
                        <NavLink exact to="/" className="nav-link" activeClassName="active-link">
                            Home
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/product" className="nav-link" activeClassName="active-link">
                            Product
                        </NavLink>

                        {/* <ProductMegaMenu /> */}
                    </li>
                    <li>
                        <NavLink to="/pricing" className="nav-link" activeClassName="active-link">
                            Pricing
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="#" className="nav-link" onClick={handleSubmit}>
                            Try Now
                        </NavLink>
                    </li>
                    {/* <li>
                        <NavLink to="/company" className="nav-link" activeClassName="active-link">
                            Company
                        </NavLink>
                    </li> */}
                    {/* <li>
                        <NavLink to="/customers" className="nav-link" activeClassName="active-link">
                            Customers
                        </NavLink>
                    </li> */}
                    {/* <li>
                        <NavLink to="/company" className="nav-link" onClick={() => setActive("LOG IN")}>
                            Community
                        </NavLink>
                    </li> */}
                    {/* <li>
                        <NavLink to="/resources" className="nav-link" activeClassName="active-link">
                            Resources
                        </NavLink>
                    </li> */}
                </ul>
                <div className='nav-2'>
                    <NavLink to="/login">
                        <ButtonFilled size="medium" borderColor="var(--data-con-blue)" fontColor="var(--data-con-blue)" fillColor="transparent">
                            LOGIN
                        </ButtonFilled>
                    </NavLink>
                    {/* <NavLink to="/register"> */}
                    <div>
                        <ButtonFilled size="medium" borderColor="#FF9922" fontColor="#fff" fillColor="#FF9922" onClick={handleSubmit}>
                            TRY NOW
                        </ButtonFilled>
                    </div>

                    {/* </NavLink> */}

                    <IconButton aria-label="Menu" className="hamburger-menu" onClick={openDrawer}>
                        <MenuIcon fontSize="small" />
                    </IconButton>
                </div>
            </nav>
        </>
    );
}