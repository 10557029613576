import React from 'react'
import { useState } from 'react';
import { UserAPIService } from '../../config/api-service';
import RaisedFormComponent from '../utils/raised-form'
import { TextField, Button } from '@material-ui/core';
import ResetPasswordComponent from '../reset-password';
import { useAlertContext } from '../../context/alert';
import useScript from '../../config/useScript';
import { useAppContext } from '../../context';


export default function ForgotPasswordComponent() {
    const [email, setEmail] = useState("");
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [formErrorMessage, setFormErrorMessage] = useState("")
    const [showResetPage, setShowResetPage] = useState(false);
    const {setAlertMessage, setSeverity, setOpen, setAlertTitle} = useAlertContext();

    const { ip } = useAppContext()
    useScript(ip)

    const handleSubmit = (event) => {
        event.preventDefault();

        setFormSubmitted(true);

        // Validate email field has a value
        if (!email || email === "")
            return;

        UserAPIService.forgotPassword({ EmailTXT: email })
            .then(res => {
                setSeverity("success");
                setAlertTitle("");
                setAlertMessage(res.data.message);
                setOpen(true);
                setShowResetPage(true);
            })
            .catch(err => {
                setFormErrorMessage(err.response.data.message);
            })
    }


    return (<>
        {showResetPage ? (<ResetPasswordComponent email={email} />) : (
            <RaisedFormComponent pageLabel="Forgot Password">
                <form noValidate onSubmit={handleSubmit} >
                    <div className="margin-2 not-sides">
                        <TextField
                            size="small"
                            className="field"
                            id="username"
                            name="username"
                            label="Email"
                            variant="outlined"
                            type="text"
                            value={email}
                            error={!email && formSubmitted}
                            onChange={e => setEmail(e.target.value)} />
                    </div>
                    <div className="error text margin-1">{formErrorMessage}</div>
                    <div className="row">
                        <Button fullWidth={true} type="submit" variant="contained" className="btn btn-primary submit align-center">Submit</Button>
                    </div>
                </form>
            </RaisedFormComponent>
        )}
    </>)
}
