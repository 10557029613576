import { Divider } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Lottie from "lottie-react";
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Dataconnector from '../../assets/images/logos/main_inverse.png';
import { ConnectorAPIService } from '../../config/api-service';
import MaterialUITheme from '../../config/material-ui-theme';
import '../../config/style.css';
// import shutdownImage from '../../assets/images/shutdown.png'
// import liveBillingImage from '../../assets/images/live_billing.png'
// import infiniteImage from '../../assets/images/infinite.png'
import CompanyLogos from '../../assets/images/company_logos.png';
import DataconnectorAnimation from '../../assets/images/connector.json';
import useScript from '../../config/useScript';
import { useAppContext } from '../../context';
import Features from '../common/Features/Features';
import FreeTrialEmailInput from '../common/FreeTrialEmailInput/FreeTrialEmailInput';
import Offers from '../common/Offers/Offers';


export default function SourceSetupComponent() {
    const [sourceImage, setSourceImage] = useState(null)
    const [connectors, setConnectors] = useState([])

    const { ip } = useAppContext()
    useScript(ip)

    useEffect(() => {
        const getConnectors = async () => {
            const { data } = await ConnectorAPIService.getConnectors()
            setConnectors(data)
        }
        getConnectors()
    }, [])

    const getConnectorName = (str) => {
        return str.split('-').join(' ')
    }


    const getSourceAnimation = (name) => {
        let animation
        try {
            animation = require(`../../assets/images/source-animation/${name.split('.')[0]}.json`)
        } catch {
            animation = null
        }
        return animation
    }

    const { name } = useParams()
    const [connector, setConnector] = useState({})
    useEffect(() => {
        window.scrollTo(0, 0)
        const con = connectors.find(con => con.ConnectorNM.toLowerCase() === getConnectorName(name))
        setConnector(con)
        if (con) {
            setSourceImage(
                getSourceAnimation(con.SourceImageTXT) ? <Lottie
                    style={{ maxWidth: 500, margin: "0 auto" }}
                    animationData={getSourceAnimation(con.SourceImageTXT)} /> 
                    : 
                    <Lottie
                        style={{ maxWidth: 500, margin: "0 auto" }}
                        animationData={DataconnectorAnimation} />
                //     <img
                //     src={SourceConnector}
                //     className="connector-img showcase-img m-1"
                //     alt={con.ConnectorNM}
                //     id="image_source" />
            )
        }
    }, [name, connectors])

    const theme = useTheme()
    const md = useMediaQuery(theme.breakpoints.up('md'))
    const lg = useMediaQuery(theme.breakpoints.up('lg'))

    return (
        <MaterialUITheme>
            <div>
                <img src={Dataconnector} alt="Dataconnector" width={120} />
                <h1 style={{ fontSize: lg ? "58px" : md ? "48px" : "33px", margin: "-30px 0 0 0", fontWeight: 500 }}>Simple access to {connector?.ConnectorNM}</h1>
                <div className="home-container main-container" style={{ maxWidth: "800px", margin: "auto", padding: lg ? "0 50px" : md ? "0 25px" : "0 10px", }}>
                    <p style={{ fontSize: "19px", margin: "0 0 10px 0" }}>Data connector is a no-code pipeline platform that moves your data<br />where you want it, when you want it in a simple, affordable way</p>
                    <FreeTrialEmailInput />
                    <div style={{ maxWidth: 500, margin: "10px auto 0" }}>
                        {sourceImage}
                    </div>
                    <Features />
                    <Divider style={{ margin: "10px 0" }} />
                    <img src={CompanyLogos} alt="Dataconnector Animation" width="900" style={{ maxWidth: "100%", height: "auto", margin: "10px auto" }} />
                </div >
                <div style={{ backgroundColor: "#00c9ff1c", padding: "40px 0" }}>
                    <Offers />
                </div>
            </div>
        </MaterialUITheme>
    )
}