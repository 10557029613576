import React, { createContext, useContext, useEffect, useState } from 'react'
import AlertProvider from './alert';
import MUIThemeProvider from './mui-theme';

const AppContext = createContext();

export const useAppContext = () => {
    return useContext(AppContext);
}

export default function AppProvider({children}) {

    const [ip, setIP] = useState(null);

    useEffect(() => {
        fetch("https://api64.ipify.org?format=json")
            .then((response) => response.json())
            .then((res) => {
                setIP(res.ip)
            })
            .catch(err => {
                setIP(null)
            })
    }, []);


    return (
        <AppContext.Provider value={{ ip }}>
            <AlertProvider>
                <MUIThemeProvider>
                    {children}
                </MUIThemeProvider>
            </AlertProvider>
        </AppContext.Provider>
    )
}