import React, { useState } from 'react';
import '../../config/style.css';
import { Card } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
// import { UserAPIService } from '../../config/api-service';
// import { useHistory } from 'react-router-dom';
import DataConnectorImage from '../../assets/images/logos/main_inverse.png';
// import { useAlertContext } from '../../context/alert';
import useScript from '../../config/useScript';
import { useAppContext } from '../../context';


export default function DemoComponent() {
    const [EmailTXT, setEmail] = useState('');
    const [FirstNM, setFirstName] = useState('');
    const [LastNM, setLastName] = useState('');
    const [CompanyNM, setCompanyName] = useState('');
    const [PrimaryPhoneNBR, setPhoneNumber] = useState('');
    const [helpMessage, setHelpMessage] = useState('');
    const [submitted, setSubmitted] = useState(false);
    // const history = useHistory();

    // const {setAlertMessage, setSeverity, setOpen, setAlertTitle} = useAlertContext();
    const { ip } = useAppContext()
    useScript(ip)


    const handleSubmit = (event) => {
        event.preventDefault();

        // UserAPIService.contact({ EmailTXT, FirstNM, LastNM, CompanyNM, PrimaryPhoneNBR, helpMessage })
        //     .then(json => {

        // setSeverity("success");
        // setAlertTitle("Submitted Successfully");
        // setAlertMessage("Your form has been submitted, and we will reach out within 24 hours.");
        // setOpen(true);

        setSubmitted(true);

        // window.location.href = window.location.origin;
        //     }
        // )
    }

    return (<>{submitted ? (
        <div className="main-container margin-5">
            <Card raised={true} className='login'>
                <div className="margin--3 top" style={{ textAlign: 'center' }}>
                    <img src={DataConnectorImage} className="main-logo-image" alt="Logo" />
                </div>
                <div className="margin-3">
                    <div className="subheader">Thanks for reaching out! Please allow us 24 hours to get back with you. </div>
                </div>
            </Card>
        </div> ) : (<div className="main-container margin-5">
            <Card raised={true} className='login'>

                <div className="margin--3 top" style={{ textAlign: 'center' }}>
                    <img src={DataConnectorImage} className="main-logo-image" alt="Logo" />
                </div>
                <div className="margin-3">
                    <div className="subheader">Want to see Data Connector in action?</div>
                    <div className="subheader">Tell us a little bit about yourself.</div>
                </div>

                <form noValidate autoComplete="off" onSubmit={handleSubmit} >
                <div className="margin-3 not-sides">
                    <TextField
                        size="small"
                        className="field"
                        id="outlined-basic"
                        label="Email"
                        variant="outlined"
                        value={EmailTXT}
                        onInput={e => setEmail(e.target.value)} />
                        </div>
                        <div className="margin-3 not-sides">
                    <TextField
                        size="small"
                        className="field"
                        id="outlined-basic"
                        label="First Name"
                        variant="outlined"
                        value={FirstNM}
                        onInput={e => setFirstName(e.target.value)} />
                        </div><div className="margin-3 not-sides">
                    <TextField
                        size="small"
                        className="field"
                        id="outlined-basic"
                        label="Last Name"
                        variant="outlined"
                        value={LastNM}
                        onInput={e => setLastName(e.target.value)} />
                        </div><div className="margin-3 not-sides">
                    <TextField
                        size="small"
                        className="field"
                        id="outlined-basic"
                        label="Phone Number"
                        variant="outlined"
                        value={PrimaryPhoneNBR}
                        onInput={e => setPhoneNumber(e.target.value)} />
                        </div><div className="margin-3 not-sides">
                    <TextField
                        size="small"
                        className="field"
                        id="outlined-basic"
                        label="Company Name (Optional)"
                        variant="outlined"
                        value={CompanyNM}
                        InputLabelProps={{style: {fontSize: '14 !important'}}}
                        // helperText="Optional"
                        onInput={e => setCompanyName(e.target.value)} />
                        </div>
                    <div className="form-group">
                        <textarea
                            className="form-control input-form"
                            id="exampleFormControlTextarea1"
                            rows="2"
                            placeholder="How can we help you?"
                            value={helpMessage}
                            onChange={e => setHelpMessage(e.target.value)}
                        />
                    </div>
                    <div className="margin-3 not-sides">
                    <TextField
                        size="small"
                        className="field"
                        id="outlined-basic"
                        label="Company Name (Optional)"
                        variant="outlined"
                        multiline
                        maxRows={2}
                        value={CompanyNM}
                        InputLabelProps={{style: {fontSize: '14 !important'}}}
                        // helperText="Optional"
                        onInput={e => setCompanyName(e.target.value)} />
                    </div>
                    <div className="row">
                        <Button fullWidth={true} type="submit" variant="contained" className="btn btn-primary submit align-center">Request now!</Button>
                    </div>
                </form>
            </Card>
        </div>)}</>
    )
}