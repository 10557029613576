import { useState, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import '../../config/style.css'
import { Popper, Box, Button, TextField, FormControl, FormControlLabel, RadioGroup, FormLabel, Radio } from '@material-ui/core'
import { UserAPIService } from '../../config/api-service'
import { handleError } from '../../config/api-response'
import { useAlertContext } from '../../context/alert'
import PasswordChecklist from "react-password-checklist"
import { useEffect } from 'react'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import MaterialUITheme from '../../config/material-ui-theme'

export default function RegisterCompanyComponent ({ setActiveStep, emailTXT, setEmailTXT, setOrganizationID }) {
    function useQuery () {
        const { search } = useLocation()
        const [key, value] = search.split('=')
        const encodedSearch = encodeURIComponent(value)
        return useMemo(() => new URLSearchParams(`${key.split('?')?.[1]}=${encodedSearch}`), [encodedSearch])
    }
    const query = useQuery()

    const [isEmailDisable, setIsEmailDisable] = useState(true)
    const [isEmailChanged, setIsEmailChanged] = useState(false)
    const [PasswordHASH, setPassword] = useState('');
    const [verifyPassword, setVerifyPassword] = useState('');
    const [FirstNM, setFirstName] = useState('')
    const [UserID, setUserID] = useState('')
    const [LastNM, setLastName] = useState('')
    const [CompanyNM, setCompanyName] = useState('')
    const [PrimaryPhoneNBR, setPhoneNumber] = useState('')
    const [hearField, setHearField] = useState('')
    const [hearOther, setHearOther] = useState('');
    const [formErrorMessage, setFormErrorMessage] = useState()
    const [formSubmitted, setFormSubmitted] = useState(false)
    const [formInvalid, setFormInvalid] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const [passwordValid, setPasswordValid] = useState(false)
    const [hearFromList, setHearFromList] = useState([]);
    const { setAlertMessage, setSeverity, setOpen, setAlertTitle } = useAlertContext()

    useEffect(() => {
        const getHearFromList = async () => {
            const { data } = await UserAPIService.getHearAboutUsOptions()
            setHearFromList(data)
        }
        getHearFromList()
    }, [])

    useEffect(() => {
        const editableEmail = query.get('editableEmail')
        if (editableEmail) {
            setEmailTXT(editableEmail)
            setIsEmailDisable(false)
            return
        }
        setEmailTXT(query.get('email') || '')
        setIsEmailDisable(true)
    }, [])

    const unchangedEmail = query.get('email') || query.get('editableEmail')
    useEffect(() => {
        if (unchangedEmail !== emailTXT) setIsEmailChanged(true)
        else {
            setIsEmailChanged(false)
            const getUserData = async () => {
                try {
                    const { data } = await UserAPIService.userDetailsByEmail(emailTXT)
                    setUserID(data?.UserID)
                    setFirstName(data?.FirstNM)
                    setLastName(data?.LastNM)
                    setPhoneNumber(data?.PrimaryPhoneNBR)
                    setHearField(data?.HowCanWeHelpTXT)
                } catch { }
            }
            getUserData()
        }

    }, [emailTXT])

    const handleSubmit = (event) => {
        event.preventDefault()
        setFormSubmitted(true)
        // Check if any fields are invalid
        let fields = [emailTXT, FirstNM, LastNM, PrimaryPhoneNBR, CompanyNM]

        let invalid = fields.some(field => field === '')
        setFormInvalid(invalid)

        if (invalid) {
            setFormErrorMessage("Please fill in the required fields.")
            return
        }

        let api = 'registerExistingUserCompany'
        if (isEmailChanged) api = 'register'

        UserAPIService[api]({
            UserID,
            EmailTXT: emailTXT,
            PasswordHASH,
            FirstNM,
            LastNM,
            CompanyNM,
            PrimaryPhoneNBR,
            HowCanWeHelpTXT: hearField,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        })
            .then(json => {
                if (json.data.user) {
                    setSeverity("success")
                    setAlertTitle("")
                    setAlertMessage('Company successfully registered')
                    setOpen(true)
                    setFormErrorMessage('')
                    setOrganizationID(json.data?.user?.OrganizationID)
                }
                setActiveStep(2)
            })
            .catch(err => handleError(err, "Form", setFormErrorMessage))
    }

    const open = Boolean(anchorEl)
    const mediaMatch = window.matchMedia('(max-width: 500px)')

    const theme = useTheme()
    const md = useMediaQuery(theme.breakpoints.up('md'))

    return (<MaterialUITheme>
        <div className="error">
            {formSubmitted ? formErrorMessage : null}
        </div>
        <Box
            component={'form'}
            noValidate
            onSubmit={handleSubmit}
            sx={{
                display: 'grid',
                gridTemplateColumns: md ? 'auto auto' : 'auto',
                rowGap: 15,
                columnGap: 10
            }}
        >
            <TextField
                size="small"
                className="field"
                name="email"
                label="Email"
                disabled={isEmailDisable}
                variant="outlined"
                inputProps={{ maxLength: 255 }}
                value={emailTXT}
                error={!emailTXT && formSubmitted}
                onInput={e => setEmailTXT(e.target.value)}
                style={{ gridColumn: '1/-1' }} />

            {isEmailChanged && <>
                <Popper open={open} anchorEl={anchorEl} placement="top-end" style={{ zIndex: 10000, marginBottom: 5 }}>
                    <div style={{ border: '1px solid #ccc', backgroundColor: '#fff', padding: 5 }}>
                        <PasswordChecklist
                            rules={["minLength", "number", "match"]}
                            minLength={6}
                            value={PasswordHASH}
                            valueAgain={verifyPassword}
                            onChange={(isValid) => setPasswordValid(isValid)}
                            iconSize={mediaMatch ? 10 : 12}
                            className={mediaMatch.matches ? "size-10" : "size-12"}
                            messages={{minLength:"Password has at least 6 characters"}}
                        />
                    </div>
                </Popper>
                <TextField
                    size="small"
                    className="field"
                    name="password"
                    label="Password"
                    variant="outlined"
                    type="password"
                    onFocus={(event) => setAnchorEl(anchorEl ? null : event.currentTarget)}
                    onBlur={(event) => setAnchorEl(anchorEl ? null : event.currentTarget)}
                    inputProps={{ maxLength: 255 }}
                    value={PasswordHASH}
                    error={(formSubmitted) && !passwordValid}
                    onInput={i => setPassword(i.target.value)} />
                <TextField
                    name="vpassword"
                    size="small"
                    className="field"
                    label="Confirm Password"
                    variant="outlined"
                    type="password"
                    onFocus={(event) => setAnchorEl(anchorEl ? null : event.currentTarget)}
                    onBlur={(event) => setAnchorEl(anchorEl ? null : event.currentTarget)}
                    inputProps={{ maxLength: 255 }}
                    value={verifyPassword}
                    error={(formSubmitted) && !passwordValid}
                    onInput={i => setVerifyPassword(i.target.value)} />
            </>}

            <TextField
                size="small"
                className="field"
                label="First Name"
                variant="outlined"
                inputProps={{ maxLength: 255 }}
                value={FirstNM}
                error={!FirstNM && formSubmitted}
                onInput={e => setFirstName(e.target.value)} />
            <TextField
                size="small"
                className="field"
                label="Last Name"
                variant="outlined"
                inputProps={{ maxLength: 255 }}
                value={LastNM}
                error={!LastNM && formSubmitted}
                onInput={e => setLastName(e.target.value)} />
            <TextField
                size="small"
                className="field"
                label="Phone Number"
                variant="outlined"
                inputProps={{ maxLength: 255 }}
                value={PrimaryPhoneNBR}
                error={!PrimaryPhoneNBR && formSubmitted}
                onInput={e => setPhoneNumber(e.target.value)} />
            <TextField
                size="small"
                className="field"
                label="Company Name"
                variant="outlined"
                inputProps={{ maxLength: 255 }}
                value={CompanyNM}
                error={!CompanyNM && formSubmitted}
                onInput={e => setCompanyName(e.target.value)} />

            <FormControl component="fieldset" style={{ gridColumn: '1/-1' }}>
                <FormLabel component="legend" style={{ textAlign: 'left', paddingBottom: 5 }}>How did you hear about us?</FormLabel>
                <RadioGroup aria-label="gender" name="gender1" value={hearField} onChange={e => setHearField(e.target.value)}>
                    {
                        hearFromList.map(({ UserHearAboutOptionsID, UserHearAboutOptionsTXT }) => (
                            <FormControlLabel
                                key={UserHearAboutOptionsID}
                                value={UserHearAboutOptionsTXT}
                                control={<Radio size="small" color="primary" style={{ paddingTop: 0, paddingBottom: 0, }} />}
                                label={UserHearAboutOptionsTXT}
                            />
                        ))
                    }
                    <FormControlLabel
                        value={hearOther}
                        control={<Radio size="small" color="primary" style={{ paddingTop: 0, paddingBottom: 0, }} />}
                        label={<>
                            Other
                            {' '}
                            {
                                <TextField size='small' placeholder='Please specify, (optional)' onChange={e => { setHearOther(e.target.value); setHearField(e.target.value) }} style={{ width: 200 }} inputProps={{ style: { fontSize: 14, paddingBottom: 3 } }} />
                            }
                        </>}
                    />
                </RadioGroup>
            </FormControl>

            <Button type="submit" variant="contained" color="primary" style={{ marginTop: 30, gridColumn: '1/-1' }}>Create Account</Button>
        </Box>
    </MaterialUITheme>
    )
}
