import { Grid, Typography } from "@material-ui/core";
import Lottie from "lottie-react";
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

export default function RowComponent({ header, description, src, flexDirection = '' }) {
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <Grid
      container
      style={{
        maxWidth: 1200,
        margin: '0 auto',
        // marginTop: "40px",
        padding: md ? "0 100px" : "0 15px",
        textAlign: 'left',
        alignItems: 'center',
        justifyContent: md ? 'flex-start' : 'center',
        flexDirection
      }}
    >
      <Grid item md={6} sm={12} xs={12} style={{ paddingLeft: md && flexDirection ? 80 : '' }}>
        <Typography component={'h2'} variant="h5" style={{ fontWeight: 500 }}>
          {header}
        </Typography>
        <Typography style={{ fontWeight: 500 }}>
          {description}
        </Typography>
      </Grid>
      <Grid item md={6} sm={12} xs={12}>
        {/* <img src={src} alt={header} width="550" style={{ maxWidth: "100%", height: "auto", margin: "10px auto" }} /> */}
        <Lottie
          style={{ maxWidth: 500, margin: "0 auto" }}
          animationData={src} />
      </Grid>
    </Grid>
  )
}