import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { UserAPIService } from "../../config/api-service";
import { useAlertContext } from "../../context/alert";

const CARD_OPTIONS = {
    iconStyle: "solid",
    style: {
        base: {
            iconColor: "#c4f0ff",
            color: "#000",
            fontWeight: 500,
            fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
            fontSize: "16px",
            fontSmoothing: "antialiased",
            ":-webkit-autofill": { color: "#fff" },
            "::placeholder": { color: "#aaa" },
        },
        invalid: {
            iconColor: "#ffc7ee",
            color: "#ffc7ee",
        },
    },
};

export default function PaymentFormComponent({
    emailTXT,
    setActiveStep,
    organizationID,
}) {
    const stripe = useStripe();
    const elements = useElements();
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [formErrorMessage, setFormErrorMessage] = useState("")
    const [termsChecked, setTermsChecked] = useState(false);

    const { setAlertMessage, setSeverity, setOpen } = useAlertContext();

    const handleSubmit = async (e) => {
        e.preventDefault();

        setSubmitDisabled(true);

        if (!termsChecked) {
            setFormErrorMessage('Accept Terms of Use and Privacy Statement to continue.')
            return
        }

        // Create Payment Method in Stripe
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement),
            billing_details: {
                email: emailTXT,
            },
        });

        // If that was successful
        if (!error) {
            // Send payment method id along with email to backend to create a stripe customer
            try {
                const { id } = paymentMethod;
                const response = await UserAPIService.paymentMethod({
                    id,
                    EmailTXT: emailTXT,
                    OrganizationID: organizationID,
                    termsPrivacyAgreement: termsChecked
                });

                if (!response.data.success) {
                    setSeverity("error");
                    setAlertMessage(response.error.message);
                    setOpen(true);
                    setSubmitDisabled(false);
                } else {
                    setActiveStep(4);
                }
            } catch (err) {
                setSubmitDisabled(false);
            }
        } else {
            setSeverity("error");
            setAlertMessage(error.message);
            setOpen(true);
            setSubmitDisabled(false);
        }

        // TODO: REMOVE, uncomment above.
        // setActiveStep(4);
    };

    useEffect(() => {
        if (termsChecked) {
            setFormErrorMessage("")
        }
    }, [termsChecked])

    return (
        <form onSubmit={handleSubmit}>
            <fieldset className="FormGroup">
                <div className="FormRow">
                    <CardElement options={CARD_OPTIONS} />
                </div>
            </fieldset>
            <div className="error text margin-1">{formErrorMessage}</div>
            <div style={{ marginBottom: "10px", textAlign: "left", fontSize: "15px" }}>
                <input type="checkbox" id="terms" name="terms" checked={termsChecked} onChange={(e) => setTermsChecked(e.target.checked)} />
                <label htmlFor="terms"> I've read and agree to the <a href="/terms" target="_blank" className='link-txt'>Terms of Use</a> and <a href="/privacy" target="_blank" className='link-txt'> Privacy Statement</a></label>
            </div>

            <div class="row">
                <Button
                    fullWidth={true}
                    type="submit"
                    variant="contained"
                    className="btn btn-primary submit align-center"
                    disabled={submitDisabled}
                >
                    Start Membership
                </Button>
            </div>
        </form>
    );
}
