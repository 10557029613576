import { useEffect } from 'react';
import DataConnectorIcon from '../../assets/images/logos/main_inverse.png';
import JaredImage from '../../assets/images/jared.jpg';
import JeffImage from '../../assets/images/Jeff.jpg';
import JoeImage from '../../assets/images/Joe.jpg';
import MikeImage from '../../assets/images/Mike.jpg';
import '../../config/style.css';
import useScript from '../../config/useScript';
import { useAppContext } from '../../context';

export default function CompanyComponent() {
    const { ip } = useAppContext()
    useScript(ip)
    return (
    <div className="main-container">
        <img src={DataConnectorIcon} className="main-logo-image" alt="data connector" />
        <div className="splash">
            <div className="title margin-1">
                Who we are
            </div>
            <div className="subheader">
                Data Connector is the fully mobile, cloud-based data connection and etl tool
            </div>
            <div className="subheader">
                that sets the industry standard on simplicity and affordability
            </div>
            <br/>
            <div className="title margin-1">
                Built on a foundation of transparency and professionalism
            </div>
            <div className="subheader">
                Meet the executive team that makes Data Connector possible with over 50 collective years of experience
            </div>
        </div>
        <div className="col-container margin-3">
            <div className="bio col">
                <div>
                    <img src={JaredImage} className="img-lg" alt="Jared Grover" />
                </div>
                <div>
                    <div>JARED GROVER</div>
                </div>
                <div>
                    <p>Jared is invested in all things data. He graduated with a degree in Computer Science and Information systems and later received an MBA. He has been working with data for over 15 years including technologies like SQL, ETL, and Business Intelligence. He has worked with data in many industries and in small to enterprise businesses. He has also had an opportunity to own his own data business. He loves to teach and mentor as much as working with the data. In his career he has been able to lead teams, teach classes, present at user conferences, and show companies how to make better decisions with their data.</p>
                    <p>Jared also spent part of his career at Domo - a data driven Business Intelligence company. At Domo Jared lead a team of data consultants implementing BI solutions to many Corporate and Enterprise companies. He also was involved with the Partner Certification program where he created curriculum, taught classes, and certified Domo partners.</p>
                </div>
            </div>
            <div className="bio col">
                <div className="col-3">
                    <img src={MikeImage} className="img-lg" alt="Michael Thurston" />
                </div>
                <div className="col-3">
                    <div >MICHAEL THURSTON</div>
                </div>
                <div className="col-3">
                    <p>Michael is a firm believer that data will make good organizations GREAT organizations. For the last decade, Michael has been helping to transform the health care industry with data best practices to improve patient outcomes, reduce costs, increase revenues, and profits. He advocates the use of data to improve processes and drive the decision-making. His skills allow him to speak to all leaders of an organization, from the C suite as well as to front line employees.</p>
                </div>
            </div>
            <div className="bio col">
                <div className="col-3">
                    <img src={JeffImage} className="img-lg" alt="Jeff Weeks" />
                </div>
                <div className="col-3">
                    <div >JEFF WEEKS</div>
                </div>
                <div className="col-3">
                    <p>Jeff is passionate about helping people and businesses make better decisions by using data driven analysis. He has worked with 100+ customers throughout his career - helping to define their business needs and to provide valuable insights to their questions through the use of data and by improving business processes.   Jeff loves identifying simple insights around complex problems and seeing how individual parts make up and affect the entire process.</p>
                    <p>Jeff began his career in Manhattan, NY working at Ernst & Young in the Data Consulting and Analytics practice.  While there, Jeff was able to work with and consult with some of the largest Financial Services institutions in the areas of Data Governance, EDW Architecture Design, Business Intelligence and Reporting.</p>
                    <p>Jeff has since worked for and worked with dozens of business intelligence tools and has lead teams of technical and business resources to implement hundreds of Business Intelligence projects.  Jeff continues in his pursuit to make businesses better by helping them make smarter decisions through the use of Dashboards and Automation.</p>
                </div>
            </div>
            <div className="bio col">
                <div className="col-3">
                    <img src={JoeImage} className="img-lg" alt="Joe Smith" />
                </div>
                <div className="col-3">
                    <div>JOE SMITH</div>
                </div>
                <div className="col-3">
                        <p>Joe is an entrepreneur at heart and a self-proclaimed data nerd. He has spent his career in almost every industry that data has touched, spending most time in the financial and medical industries. He is an advocate of data-driven decisions and passionate about simplifying complex problems.</p>
                        <p>Joe has degrees in Business Management and Information Systems. He is happily married with two wonderful children.</p>
                </div>
            </div>
        </div>
    </div>
    )
}