import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentFormComponent from './payment-form';
import LockIcon from '@material-ui/icons/Lock';

const { REACT_APP_STRIPE_PUBLIC_KEY } = process.env;
const stripePromise = loadStripe(REACT_APP_STRIPE_PUBLIC_KEY);

export default function PaymentComponent({ emailTXT, setActiveStep, organizationID }) {
    return (<>
        <p>Your membership starts <br /> as soon as you set up payment. </p>
        <p style={{ marginBottom: '1.33em' }}>Cancel online anytime.</p>
        <p style={{ marginBottom: '1.33em' }}>You will not be charged until after your 7 day trial ends.</p>

        <div className='flex-row justify-end aligns-center' style={{ marginRight: "1.15rem" }}>
            <span className='size-12' style={{ color: "#7a7a7a" }}>Secure Server</span>
            <LockIcon style={{ color: "#ff9922", fontSize: 14, marginLeft: 3 }} />
        </div>
        <div className="">
            <Elements stripe={stripePromise}>
                <PaymentFormComponent emailTXT={emailTXT} setActiveStep={setActiveStep} organizationID={organizationID} />
            </Elements>
        </div>
    </>)
}
