import React, { useState } from 'react';
import { Grid, MenuItem, Paper, TextField, Typography } from '@material-ui/core';
import useCaptureEvent from '../../config/useCaptureEvent';
import { useAppContext } from '../../context';

const PricingCard = ({ titleOne = '', titleTwo = '', titleThree = '', titleFour = '', inputHidden = false, connectorInputHidden = false, initialConnector }) => {

    const [inputs, setInputs] = useState({
        connectors: initialConnector ? initialConnector : 2,
        numberOfRows: '1,000,000',
    })

    const { ip } = useAppContext()
    const captureEvent = useCaptureEvent()

    const handleChange = (event) => {
        setInputs({ ...inputs, [event.target.name]: event.target.value })
    }

    const addCommas = (num) =>
        num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");

    const handleNumberOfRowsChange = (event) => {
        setInputs({ ...inputs, [event.target.name]: addCommas(removeNonNumeric(event.target.value)) })
    }

    return (
        <Paper
            elevation={0}
            style={{
                backgroundColor: '#fff',
                borderRadius: 10,
                padding: "10px 20px",
            }}
            onClick={() => captureEvent(ip, "Price Calculator", `Used ${titleOne} Calculator`)}
        >
            <Typography style={{ fontWeight: 700, fontSize: "18px", margin: '5px auto' }}>{titleOne}</Typography>
            <Typography component={'p'}>{titleTwo}</Typography>
            <Typography style={{ fontWeight: 700, fontSize: "18px", margin: '5px auto 0px auto' }}>{titleThree}</Typography>
            <Typography style={{ fontWeight: 700, fontSize: "18px", margin: titleFour ? '0px auto 20px auto' : '60px auto' }}>{titleFour}</Typography>

            <Grid
                container
                spacing={2}
                style={{ visibility: inputHidden && "hidden" }}>
                <Grid item xs={5} style={{ display: connectorInputHidden && "none" }} >
                    <TextField
                        fullWidth
                        variant="outlined"
                        size="medium"
                        select
                        name="connectors"
                        value={inputs['connectors']}
                        placeholder="Connectors"
                        label="Connectors"
                        onChange={handleChange}
                        onBlur={() => captureEvent(ip, "Price Calculator", `${titleOne} Calculator: Entered ${inputs.connectors} connectors`)}
                    >
                        <MenuItem value={1}>
                            1
                        </MenuItem>
                        <MenuItem value={2}>
                            2
                        </MenuItem>
                        <MenuItem value={3}>
                            3
                        </MenuItem>
                        <MenuItem value={4}>
                            4
                        </MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={connectorInputHidden ? 12 : 7}>
                    <TextField
                        onBlur={() => captureEvent(ip, "Price Calculator", `${titleOne} Calculator: Entered ${inputs.numberOfRows}`)}
                        fullWidth
                        // className={classes.inputField}
                        variant="outlined"
                        size="medium"
                        // type="number"
                        type="text"
                        inputProps={{ min: 0 }}
                        value={inputs['numberOfRows']}
                        // value={value}
                        name="numberOfRows"
                        placeholder="Monthly Rows"
                        label="Monthly Rows"
                        onChange={handleNumberOfRowsChange}
                    />
                </Grid>
            </Grid>
            <Typography style={{ fontWeight: 900, fontSize: "34px", marginTop: '10px', color: "var(--data-con-blue)" }}>${(((inputs.numberOfRows ? parseInt(inputs.numberOfRows.split(",").join("")) : 0) * 1.99 / 1000000) + (inputs.connectors * 49)).toFixed(2)}</Typography>
            <Typography style={{ textTransform: "uppercase", fontWeight: 700, fontSize: "14px", color: "gray", margintop: 0 }}>Per Month</Typography>
        </Paper>
    );
};

export default PricingCard;