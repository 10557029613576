import React, {useState, useEffect} from 'react';
import '../../config/style.css';
import { Divider } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Lottie from "lottie-react";
import MaterialUITheme from '../../config/material-ui-theme';
import Dataconnector from '../../assets/images/logos/main_inverse.png';
import DataconnectorAnimation from '../../assets/images/connector.json';
import CompanyLogos from '../../assets/images/company_logos.png'
import Features from '../common/Features/Features';
import Offers from '../common/Offers/Offers';
import FreeTrialEmailInput from '../common/FreeTrialEmailInput/FreeTrialEmailInput';
import { OrganizationAPIService } from '../../config/api-service';
import useScript from '../../config/useScript';
import { useAppContext } from '../../context';
import ReviewCarouselComponent from '../carousel';

export default function HomeComponent() {
    const [reviews, setReviews] = useState([]);
    const theme = useTheme()
    const lg = useMediaQuery('(min-width:1480px)')
    const md = useMediaQuery(theme.breakpoints.up('md'))

    const { ip } = useAppContext()
    useScript(ip)

    useEffect(() => {
        OrganizationAPIService.getReviews()
            .then(res => {
                if (res && res.data && res.data.reviews) {
                    
                    const newReviews = res.data.reviews.map(r => {
                        const logo = require(`./../../assets/images/reviews/${r.ReviewLogoTXT}`).default
                        const profile = require(`./../../assets/images/reviews/${r.ReviewProfilePictureTXT}`).default
                        return {...r, ReviewLogoTXT: logo, ReviewProfilePictureTXT: profile};
                    })
                    setReviews(newReviews);
                }
            })
    }, [])
    

    return (
        <MaterialUITheme>
            <div>
                <img src={Dataconnector} alt="Dataconnector" width={120} />
                <h1 style={{ fontSize: lg ? "58px" : md ? "48px" : "33px", margin: "-30px 0 0 0", fontWeight: 500 }}>Simple access to data</h1>
                <div className="home-container main-container" style={{ maxWidth: "800px", margin: "auto", padding: lg ? "0 50px" : md ? "0 25px" : "0 10px", }}>
                    <p style={{ fontSize: "19px", margin: "0 0 10px 0" }}>Data connector is a no-code pipeline platform that moves your data<br />where you want it, when you want it in a simple, affordable way</p>
                    <FreeTrialEmailInput />
                    <Lottie
                        style={{ maxWidth: 500, margin: "0 auto" }}
                        animationData={DataconnectorAnimation} />
                    <Features />
                    <Divider style={{ margin: "10px 0" }} />
                    <img src={CompanyLogos} alt="Dataconnector Animation" width="900" style={{ maxWidth: "100%", height: "auto", margin: "10px auto" }} />
                </div >
                <div className="review-section">
                    <ReviewCarouselComponent items={reviews} />
                </div>
                <div style={{ backgroundColor: "#00c9ff1c", padding: "40px 0" }}>
                    <Offers />
                </div>
            </div>
        </MaterialUITheme>
    )
}