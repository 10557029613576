import React from 'react';
import Pri from '../../../assets/images/icons/account_login_white.png'

export function ButtonOutlined ({ children, size, borderColor = "#00bfff", fontColor = "#00bfff" }) {
    const styles = {
        border: `1px solid ${borderColor}`,
        color: fontColor,
        borderRadius: 3,
        backgroundColor: '#fff',
        padding: size === "medium" ? "7px" : size === "large" ? "10px" : "5px",
        margin: 3,
        fontWeight: 500
    }

    return <button style={styles} className='btn-base'>
        {children}
    </button>;
}

export function ButtonFilled({ children, size, fillColor = "#00bfff", borderColor = "#00bfff", fontColor = "#fff", onClick }) {
    const styles = {
        backgroundColor: fillColor,
        border: `1px solid ${borderColor}`,
        color: fontColor,
        borderRadius: 3,
        padding: size === "medium" ? "7px" : size === "large" ? "10px" : "5px",
        margin: 3,
        fontWeight: 600,
    }

    return <button style={styles} className='btn-base' onClick={onClick}>
        {children}
    </button>;
}

export function ButtonProfile () {
    const styles = {
        backgroundColor: 'transparent',
        margin: 3,
        border: 'none'
    }
    return <button style={styles} className='btn-base'>
        <img className='profile-icon' src={Pri} />
    </button>
}
