// import { Box, Card, CardActionArea, CardMedia, Divider, Grid, Typography } from '@material-ui/core';
import MaterialUITheme from '../../config/material-ui-theme';
import Dataconnector from '../../assets/images/logos/main_inverse.png';
import Offers from '../common/Offers/Offers';
import Features from '../common/Features/Features';
import useScript from '../../config/useScript';
import { useAppContext } from '../../context';


export default function Product() {
  const { ip } = useAppContext()
  useScript(ip)
  return (
    <MaterialUITheme>
      <div>
        <img src={Dataconnector} alt="Dataconnector" width={120} />
        <div style={{ padding: "0 0 40px 0" }}>
          <Offers />
        </div>
        <Features />
      </div>


    </MaterialUITheme>
  )
}