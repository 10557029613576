import { useEffect, useState } from 'react';
import { deviceDetect, getUA } from 'react-device-detect';
import { useLocation } from 'react-router';
import { MarketingEventLogAPIService } from './api-service';
import { Cookies } from "react-cookie-consent";

const useScript = (ip) => {

    const [deviceDetails,] = useState({ ...deviceDetect(getUA) });
    const location = useLocation();

    useEffect(() => {
        if (ip) {
            MarketingEventLogAPIService.addMarketingEventLog({
                IPAddressTxt: ip,
                DeviceDsc: deviceDetails.isMobile ? `${deviceDetails.vendor} ${deviceDetails.model}` : `${deviceDetails.browserName} ${deviceDetails.osName}`,
                EventLogTypeDsc: "Navigation",
                EventLogDsc: `Visited url route.`,
                EventPageUrlTxt: location.pathname + location.search + location.hash,
                DeviceDimensionsTxt: `${window.innerWidth}X${window.innerHeight}`,
            })
        }

        if (Cookies.get("dataconnectorcookie") === 'true') {
            const script = document.createElement('script');

            //LinkedIn Start
            const _linkedin_partner_id = "4960033";
            window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
            window._linkedin_data_partner_ids.push(_linkedin_partner_id);

            (function (l) {
                if (!l) {
                    window.lintrk = function (a, b) { window.lintrk.q.push([a, b]) };
                    window.lintrk.q = []
                }
                var s = document.getElementsByTagName("script")[0];
                var b = document.createElement("script");
                b.type = "text/javascript"; b.async = true;
                b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
                s.parentNode.insertBefore(b, s);
            })(window.lintrk);
            //LinkedIn End

            script.src = "https://www.googletagmanager.com/gtag/js?id=G-W967ZFK3DG";
            script.async = true;

            window.dataLayer = window.dataLayer || [];
            function gtag() { window.dataLayer.push(arguments); }
            gtag('js', new Date());

            gtag('config', 'G-W967ZFK3DG');

            document.body.appendChild(script);

            return () => {
                document.body.removeChild(script);
            }
        }

    }, [deviceDetails.browserName, deviceDetails.isMobile, deviceDetails.model, deviceDetails.osName, deviceDetails.vendor, ip, location.pathname]);
};

export default useScript;



