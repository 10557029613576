import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { UserAPIService } from '../../config/api-service';
import { Link, useHistory, useLocation } from 'react-router-dom';
import './style.css';
import RaisedFormComponent from '../utils/raised-form';
import { handleError } from '../../config/api-response';
import useScript from '../../config/useScript';
import { useAppContext } from '../../context';

const { REACT_APP_URL } = process.env;

export default function LoginComponent () {
    const { state } = useLocation()
    const [EmailTXT, setEmail] = useState(state?.email ? state?.email : "");
    const [PasswordHASH, setPassword] = useState('');
    const [loginErrorMessage, setLoginErrorMessage] = useState("");

    const history = useHistory()

    const { ip } = useAppContext()
    useScript(ip)

    const handleSubmit = (event) => {
        event.preventDefault();

        UserAPIService.login({ EmailTXT, PasswordHASH })
            .then(json => {
                if (json.status === 200) {
                    if (json?.data?.temporaryPassword) history.push({ pathname: '/change-password', state: { EmailTXT, PasswordHASH } })
                    else window.location.assign(REACT_APP_URL)
                } else {
                    if (json.status === 404) {
                        throw Error("Looks like there has been an error on our end. Sorry! (Error code 404)")
                    } else if (json.status === 401) {
                    }
                }
            })
            .catch(err => handleError(err, "Form", setLoginErrorMessage));
    }

    return (
        <RaisedFormComponent pageLabel="Login">
            <form noValidate onSubmit={handleSubmit} >
                <div className="margin-2 not-sides">
                    <TextField
                        size="small"
                        className="field"
                        id="username"
                        name="username"
                        label="Email"
                        variant="outlined"
                        type="text"
                        value={EmailTXT}
                        onChange={e => setEmail(e.target.value)} />
                </div>
                <div className="not-sides">
                    <TextField
                        size="small"
                        className="field"
                        id="password"
                        name="password"
                        label="Password"
                        variant="outlined"
                        type="password"
                        value={PasswordHASH}
                        onChange={i => setPassword(i.target.value)} />
                </div>
                <div className="error text margin-1">{loginErrorMessage}</div>
                <div className="forgot bottom">
                    <Link to="/forgot" className="link link-txt">Forgot Password</Link>
                </div>
                <div className="row">
                    <Button fullWidth={true} type="submit" variant="contained" className="btn btn-primary submit align-center">Submit</Button>
                </div>
            </form>
        </RaisedFormComponent>
    )
}