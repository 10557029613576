import React from 'react';
import { Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import RowComponent from '../RowComponent/RowComponent';
import EasyConnect from '../../../assets/images/connect.json'
import SimplyChoose from '../../../assets/images/choose.json'
import AutomaticallyLoad from '../../../assets/images/load.json'
import FreeTrialEmailInput from '../FreeTrialEmailInput/FreeTrialEmailInput';

const Offers = () => {
    const theme = useTheme()
    const lg = useMediaQuery('(min-width:1480px)')
    const md = useMediaQuery(theme.breakpoints.up('md'))
    return (
        <div>
            <h1>Stop spending hours digging through reports</h1>
            <Typography>Connect directly to your online applications-like Salesforce, Quickbooks,<br />Social Media and Advertising platforms-and pull that data to one location.</Typography>
            <div style={{ maxWidth: "875px", margin: "auto", paddingTop:"50px"}}>
                <RowComponent header="Easily connect" description={<p style={{ margin: 0 }}>Connecting to your data is as easy as <br /> logging in.</p>} src={EasyConnect} />
                <RowComponent header="Simply choose" description="Pick the exact data you want and where you want it." src={SimplyChoose} flexDirection="row-reverse" />
                <RowComponent header="Automatically load" description="Have peace of mind that your data is flowing when you need it. Wake up knowing your data is right where it belongs." src={AutomaticallyLoad} />
            </div>
            <div style={{ maxWidth: "680px", margin: "auto", padding: lg ? "0 50px" : md ? "0 25px" : "0 10px", }}>
                <FreeTrialEmailInput />
            </div>
        </div>
    );
};

export default Offers;