import React from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { createTheme } from '@material-ui/core/styles';
// import { createTheme, ThemeProvider } from '@mui/material/styles';

export default function MUIThemeProvider({children}) {
    const theme = createTheme({
        palette: {
            primary: {
                main: '#00bfff',
            }
        }
    });

    return (
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    );
}