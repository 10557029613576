import React from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Button from '@material-ui/core/Button';

export default function PlansComponent({ setActiveStep }) {
    const handleNext = (e) => {
        e.preventDefault();
        setActiveStep(3)
    }
    return (
        <>
            <h3 className='text' style={{color: '#686868', marginTop: '1.33em', marginBottom: '1.33em'}}>
                $49 per connector per month<br />
                $1.99 / million rows inserted
            </h3>
            <div>
                <CheckItemComponent label="No commitments, cancel anytime" />
                <CheckItemComponent label="Unlimited data and rows" />
                <CheckItemComponent label="Unlimited access to your data" />
                <CheckItemComponent label="Unlimited users" />
                <CheckItemComponent label="Unlimited pipelines" />
                <CheckItemComponent label="Affordable prices" />
                {/* <CheckItemComponent label="Full Support" /> */}
                <CheckItemComponent label="HIPAA compliance available" />
                <CheckItemComponent label="No coding necessary" />
            </div>
            <div class="row" style={{marginTop: '1.33em'}}>
                <Button fullWidth={true} onClick={handleNext} variant="contained" className="btn btn-primary submit align-center">Next</Button>
            </div>
        </>
    )
}

function CheckItemComponent({ label }) {
    return (<div className='flex-row aligns-center justify-start w-1' style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: 10, marginBottom: 10 }}>
        <CheckCircleIcon style={{ color: "#4bb543", marginRight: 10 }} />
        <span>
            {label}
        </span>
    </div>)
}