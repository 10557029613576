import { Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import MaterialUITheme from '../../config/material-ui-theme';
import Dataconnector from '../../assets/images/logos/main_inverse.png';
// import speedImage from '../../assets/images/speed.png'
// import shutdownImage from '../../assets/images/shutdown.png'
// import liveBillingImage from '../../assets/images/live_billing.png'
// import infiniteImage from '../../assets/images/infinite.png'
import PricingCard from './PricingCard';
import FreeTrialEmailInput from '../common/FreeTrialEmailInput/FreeTrialEmailInput';
import Features from '../common/Features/Features';
import useScript from '../../config/useScript';
import { useAppContext } from '../../context';


const PricingPage = () => {

  const theme = useTheme()
  const lg = useMediaQuery('(min-width:1480px)')
  const md = useMediaQuery(theme.breakpoints.up('md'))

  const { ip } = useAppContext()
  useScript(ip)

  return (
    <MaterialUITheme>
      <div style={{ backgroundColor: "rgb(247 247 247)", paddingBottom: 40 }}>
        <div style={{ padding: '5px 16px', backgroundColor: "#fff" }}>
          <img src={Dataconnector} alt="Dataconnector" width={120} />
          <h1 style={{ fontSize: lg ? "58px" : md ? "48px" : "33px", margin: "-30px 0 0 0", fontWeight: 500 }}>Transparent Pricing</h1>
          <Typography style={{ fontSize: lg ? "26px" : md ? "26px" : "22px", margin: "10px 0 30px 0" }}>
            Pricing should always be transparent. No sales <br />calls, no demos necessary, and no hidden costs. <br />Find out today how affordable accessing your data can be.
          </Typography>
        </div>
        <div style={{ padding: '0px 16px' }}>
          <Typography style={{ fontWeight: 700, fontSize: "24px", margin: '5px auto' }}>2-Part Pricing Model</Typography>
          <Typography style={{ fontWeight: 600, fontSize: "18px", margin: 0 }}>1. Active Connectors (Unlimited with Enterprise)</Typography>
          <Typography style={{ fontWeight: 600, fontSize: "18px", marginBottom: '10px' }}>2. Rows Transferred</Typography>
          <Grid
            container
            spacing={2}
            style={{
              textAlign: 'center',
              alignItems: 'center',
              justifyContent: 'center',
              padding: lg ? "0 200px" : md ? "0 90px" : "0 10px"
            }}
          >
            {/* <Grid item xs={12} sm={6} md={3}>
              <PricingCard titleOne='Free' titleTwo={<p style={{ marginBottom: '32px' }}>Have a million, on us</p>} titleThree='1M rows' inputHidden={true} price={0} />
            </Grid> */}
            <Grid item xs={12} sm={12} md={12} lg={3}>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <PricingCard titleOne='Economy' titleTwo={<p style={{ margin: 0 }}>For those needing fewer than<br />5 connectors</p>} titleThree='$49/Connector' titleFour='$1.99/1M rows' />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <PricingCard titleOne='Enterprise' titleTwo={<p style={{ margin: 0 }}>For those needing 5<br />or more connectors</p>} titleThree='$245 Unlimited Connectors' titleFour='$1.99/1M rows' connectorInputHidden={true} initialConnector={5} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={3}>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={6} style={{ paddingBottom: '20px' }}>
              <div style={{ margin: "auto" }}>
                <FreeTrialEmailInput />
              </div>
            </Grid>
          </Grid>
        </div>
        <div style={{ margin: "auto", marginTop: "30px", backgroundColor: "#fff", padding: "20px", maxWidth: 770 }}>
          <h1 style={{ fontWeight: 400, marginBottom: 40 }}>Included Features</h1>
          <Features fontSize={18} fontWeight={400} />
        </div>
      </div>
    </MaterialUITheme>
  );
};

export default PricingPage;