import { Avatar } from '@material-ui/core'
import React from 'react';
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';


export default function ReviewCarouselComponent({items}) {
    return (
            <Slider infinite={true} duration={2000} autoplay={2000} nextButton={<NextButton />} previousButton={<PreviousButton />} >
                {items.map((item, index) => (
                    <div key={index} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <div class="review-content-container">
                            <p class="review-text">"{item.ReviewTXT}"</p>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                <ProfileDisplay name={item.ReviewUserNM} link={item.ReviewProfilePictureTXT} title={item.ReviewUserTitle} />
                                <CompanyProfile name={item.ReviewUserNM} link={item.ReviewLogoTXT} />
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
    )  
}


function PreviousButton() {
    return (
        <div style={{backgroundColor: "#fff", borderRadius: '50px'}}>
            <ArrowBackIcon class="MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-tzssek-MuiSvgIcon-root review-arrows" />
        </div>
    )
}

function NextButton() {
    return (
        <div style={{backgroundColor: "#fff", borderRadius: '50px'}}>
            <ArrowForwardIcon class="MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-tzssek-MuiSvgIcon-root review-arrows" />
        </div>
    )
}

function ProfileDisplay({name, title, link}) {
    return (
        <div style={{display: 'flex', alignItems: 'center'}}>
            <Avatar alt={name} src={link} />
            <div style={{marginLeft: 5}}>
                <p class="review-descriptor review-name">{name}</p>
                <p class="review-descriptor review-title">{title || ""}</p>
            </div>
        </div>
    )
}

function CompanyProfile({name, link}) {
    return (
        <div>
            <img class="review-company-image" src={link} alt={name} />
        </div>
    )
}