import { useState } from 'react';
import { UserAPIService } from '../../config/api-service';
import RaisedFormComponent from '../utils/raised-form'
import { TextField, Button, Popper } from '@material-ui/core';
import PasswordChecklist from "react-password-checklist"
import { useAlertContext } from '../../context/alert';
import { useHistory } from 'react-router';
import { handleError } from '../../config/api-response';
import useScript from '../../config/useScript';
import { useAppContext } from '../../context';


export default function ResetPasswordComponent({ email }) {
    const [code, setCode] = useState("");
    const [password, setPassword] = useState("");
    const [verifyPassword, setVerifyPassword] = useState("");
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [formErrorMessage, setFormErrorMessage] = useState("");
    const [anchorEl, setAnchorEl] = useState(null)
    const [passwordValid, setPasswordValid] = useState(false)
    const history = useHistory()
    const { setAlertMessage, setSeverity, setOpen, setAlertTitle } = useAlertContext();

    const mediaMatch = window.matchMedia('(max-width: 500px)')
    const open = Boolean(anchorEl)

    const { ip } = useAppContext()
    useScript(ip)

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!passwordValid) {
            setFormErrorMessage('Password did not meet the requirements.')
            return
        }

        setFormSubmitted(true);

        // Validate email field has a value
        if (!code || code === "" || !password || password === "" || !verifyPassword || verifyPassword === "")
            return;

        UserAPIService.resetPassword({ code, password, email })
            .then(res => {
                setFormErrorMessage('')
                setSeverity("success");
                setAlertTitle("");
                setAlertMessage(res.data.message);
                setOpen(true);
                history.push('/login')
            })
            .catch(err => handleError(err, "Form", setFormErrorMessage));
    }
    return (
        <RaisedFormComponent pageLabel="Reset Password">
            <form noValidate onSubmit={handleSubmit} >
                <Popper open={open} anchorEl={anchorEl} placement="top-end" style={{ zIndex: 10000, marginBottom: 5 }}>
                    <div style={{ border: '1px solid #ccc', backgroundColor: '#fff', padding: 5 }}>
                        <PasswordChecklist
                            rules={["minLength", "number", "match"]}
                            minLength={6}
                            value={password}
                            valueAgain={verifyPassword}
                            onChange={(isValid) => setPasswordValid(isValid)}
                            iconSize={mediaMatch ? 10 : 12}
                            className={mediaMatch.matches ? "size-10" : "size-12"}
                            messages={{minLength:"Password has at least 6 characters"}}
                        />
                    </div>
                </Popper>

                <div className="margin-2 not-sides">
                    <TextField
                        size="small"
                        className="field"
                        id="username"
                        name="username"
                        label="Verification Code"
                        variant="outlined"
                        type="text"
                        value={code}
                        error={!code && formSubmitted}
                        onChange={e => setCode(e.target.value)} />
                </div>
                <div className="margin-2 not-sides">
                    <TextField
                        size="small"
                        className="field"
                        id="username"
                        name="username"
                        label="New Password"
                        variant="outlined"
                        type="password"
                        value={password}
                        error={!password && formSubmitted}
                        onFocus={(event) => setAnchorEl(anchorEl ? null : event.currentTarget)}
                        onBlur={(event) => setAnchorEl(anchorEl ? null : event.currentTarget)}
                        onChange={e => setPassword(e.target.value)} />
                </div>
                <TextField
                    size="small"
                    className="field"
                    id="username"
                    name="username"
                    label="Verify Password"
                    variant="outlined"
                    type="password"
                    value={verifyPassword}
                    error={!verifyPassword && formSubmitted}
                    onFocus={(event) => setAnchorEl(anchorEl ? null : event.currentTarget)}
                    onBlur={(event) => setAnchorEl(anchorEl ? null : event.currentTarget)}
                    onChange={e => setVerifyPassword(e.target.value)}
                />
                <div className="error text margin-1">{formErrorMessage}</div>
                <div className="row">
                    <Button fullWidth={true} type="submit" variant="contained" className="btn btn-primary submit align-center">Submit</Button>
                </div>
            </form>
        </RaisedFormComponent >
    )
}
