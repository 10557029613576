import React from 'react';
import ReactDOM from 'react-dom';
import './app/config/style.css';
import RouterComponent from './app/config/router';
import AppProvider from './app/context';
import Footer from './app/components/footer';

ReactDOM.render(
    <React.StrictMode>
        <div className="App">
            <div style={{ flex: 1 }}>
                <AppProvider>
                    <RouterComponent />
                </AppProvider>
            </div>
            <Footer />
        </div>
    </React.StrictMode>,
    document.getElementById('root')
);
