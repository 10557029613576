import React from 'react';
import Button from '@material-ui/core/Button';

export default function FinalizeComponent ({ emailTXT }) {
    const handleSubmit = (e) => {
        e.preventDefault();
        window.location.assign(window.location.origin + "/login");
    }

    return (<>
        <div className="row">
            <p className='text'>Please look for the confirmation email we just sent to you at {emailTXT}. You may have to look in your junk or spam folder to find it.</p>
            <Button
                fullWidth={true}
                variant="contained"
                onClick={handleSubmit}
                className="btn btn-primary submit align-center"
            >
                Get Started
            </Button>
        </div>
    </>)
}
