import React from 'react';
import { Typography } from '@material-ui/core';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const Features = ({ fontSize = "2em", fontWeight = "bold" }) => {
    const theme = useTheme()
    const sm = useMediaQuery(theme.breakpoints.up('sm'))
    return (
        <div style={{ marginTop: "-35px", marginBottom: "20px" }}>
            <Typography style={{ fontSize, fontWeight, margin: "15px" }}>Great features should come standard</Typography>
            <div style={{ display: "flex", flexDirection: sm ? "row" : "column", textAlign: "center", alignItems: "center", justifyContent: "center" }}>
                <div style={{ padding: "5px 10px" }}>
                    <Typography component={'div'} style={{ display: "flex", alignItems: "center", justifyContent: "left" }}>
                        <CheckCircleIcon sx={{ color: "var(--data-con-blue)", fontSize: "0.9rem", marginRight: "2px" }} />
                        <Typography>
                            Unlimited Users
                        </Typography>
                    </Typography>
                    <Typography component={'div'} style={{ display: "flex", alignItems: "center", justifyContent: "left" }}>
                        <CheckCircleIcon sx={{ color: "var(--data-con-blue)", fontSize: "0.9rem", marginRight: "2px" }} />
                        <Typography>
                            Free setup assistance
                        </Typography>
                    </Typography>
                    <Typography component={'div'} style={{ display: "flex", alignItems: "center", justifyContent: "left" }}>
                        <CheckCircleIcon sx={{ color: "var(--data-con-blue)", fontSize: "0.9rem", marginRight: "2px" }} />
                        <Typography>
                            24x7 email support
                        </Typography>
                    </Typography>
                    <Typography component={'div'} style={{ display: "flex", alignItems: "center", justifyContent: "left" }}>
                        <CheckCircleIcon sx={{ color: "var(--data-con-blue)", fontSize: "0.9rem", marginRight: "2px" }} />
                        <Typography>
                            6 hr support SLA
                        </Typography>
                    </Typography>
                    <Typography component={'div'} style={{ display: "flex", alignItems: "center", justifyContent: "left" }}>
                        <CheckCircleIcon sx={{ color: "var(--data-con-blue)", fontSize: "0.9rem", marginRight: "2px" }} />
                        <Typography>
                            Live billing
                        </Typography>
                    </Typography>
                </div>
                <div style={{ padding: "5px 10px" }}>
                    <Typography component={'div'} style={{ display: "flex", alignItems: "center", justifyContent: "left" }}>
                        <CheckCircleIcon sx={{ color: "var(--data-con-blue)", fontSize: "0.9rem", marginRight: "2px" }} />
                        <Typography>
                            Dedicated Data Architect
                        </Typography>
                    </Typography>
                    <Typography component={'div'} style={{ display: "flex", alignItems: "center", justifyContent: "left" }}>
                        <CheckCircleIcon sx={{ color: "var(--data-con-blue)", fontSize: "0.9rem", marginRight: "2px" }} />
                        <Typography>
                            Account Manager
                        </Typography>
                    </Typography>
                    <Typography component={'div'} style={{ display: "flex", alignItems: "center", justifyContent: "left" }}>
                        <CheckCircleIcon sx={{ color: "var(--data-con-blue)", fontSize: "0.9rem", marginRight: "2px" }} />
                        <Typography>
                            HIPAA Compliance available
                        </Typography>
                    </Typography>
                    <Typography component={'div'} style={{ display: "flex", alignItems: "center", justifyContent: "left" }}>
                        <CheckCircleIcon sx={{ color: "var(--data-con-blue)", fontSize: "0.9rem", marginRight: "2px" }} />
                        <Typography>
                            Unlimited data loads
                        </Typography>
                    </Typography>
                    <Typography component={'div'} style={{ display: "flex", alignItems: "center", justifyContent: "left" }}>
                        <CheckCircleIcon sx={{ color: "var(--data-con-blue)", fontSize: "0.9rem", marginRight: "2px" }} />
                        <Typography>
                            Extensive log monitoring
                        </Typography>
                    </Typography>
                </div>
            </div>
        </div>
    );
};

export default Features;