import React from "react";
import CookieConsent, { Cookies } from "react-cookie-consent";
import {
    BrowserRouter as Router, Route, Switch
} from "react-router-dom";
import ChangeSystemPassword from "../components/change-system-password";
import CompanyComponent from "../components/company";
import DemoComponent from "../components/demo";
import ForgotPasswordComponent from "../components/forgot";
import HomeComponent from "../components/home";
import FreeTrialLogin from "../components/home/FreeTrialLogin";
import SourceSetupComponent from "../components/home/SourceSetup";
import LegalComponent from "../components/legal";
import LoginComponent from '../components/login';
import NavigationComponent from '../components/navigation';
import PricingPage from "../components/pricing/PricingPage";
import PrivacyComponent from "../components/privacy";
import ProductPage from "../components/product/Product";
import ResetPasswordComponent from "../components/reset-password";
import { SignupComponent } from "../components/sign-up";
import RegisterCompanyIndex from "../components/sign-up/register-company-index";
import TermsComponent from "../components/terms";
import UnsubscribeEmail from "../components/unsubscribe/UnsubscribeEmail";

const { REACT_APP_MARKETING_SITE } = process.env;


export default function RouterComponent() {
    return (<>
        {/* Navigation for the app */}
        <Router>
            < NavigationComponent />
            <Switch>
                <Route exact path="/">
                    <HomeComponent />
                </Route>
                <Route exact path="/source-connector/:name">
                    <SourceSetupComponent />
                </Route>
                <Route exact path="/destination-connector/:name">
                    <SourceSetupComponent />
                </Route>
                {/* <Route exact path="/salesforce-connector">
                    <SalesforceConnector />
                </Route>
                <Route exact path="/quickbooks-connector">
                    <QuickbooksConnector />
                </Route> */}
                <Route exact path="/login">
                    <LoginComponent />
                </Route>
                <Route exact path="/login-setup">
                    <FreeTrialLogin />
                </Route>
                <Route exact path="/forgot">
                    <ForgotPasswordComponent />
                </Route>
                <Route exact path="/reset">
                    <ResetPasswordComponent />
                </Route>
                <Route exact path="/change-password">
                    <ChangeSystemPassword />
                </Route>
                <Route exact path="/product">
                    <ProductPage />
                </Route>
                <Route exact path="/pricing">
                    <PricingPage />
                </Route>
                <Route exact path="/company">
                    <CompanyComponent />
                </Route>
                <Route exact path="/demo">
                    <DemoComponent />
                </Route>
                <Route exact path="/register">
                    <SignupComponent />
                </Route>
                <Route exact path="/register-company">
                    <RegisterCompanyIndex />
                </Route>
                <Route exact path="/legal">
                    <LegalComponent />
                </Route>
                <Route exact path="/terms">
                    <TermsComponent />
                </Route>
                <Route exact path="/privacy">
                    <PrivacyComponent />
                </Route>
                <Route exact path="/unsubscribe">
                    <UnsubscribeEmail />
                </Route>
            </Switch>
        </Router>
        {/* cookie */}
        <CookieConsent
            location="bottom"
            buttonText="Ok, Got it"
            disableStyles
            flipButtons
            cookieName="dataconnectorcookie"
            cookieValue={true}
            sameSite="strict"
            style={{
                alignItems: "baseline",
                background: "var(--data-con-blue)",
                color: "white",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                left: "0px",
                position: "fixed",
                width: "100%",
                zIndex: "999",
                bottom: "0px",
                padding: "10px 0"
            }}
            buttonStyle={{ color: "#fff", fontSize: "13px", fontWeight: 500, background: "var(--orange)", border: "none", padding: "5px 10px", borderRadius: "5px", margin: "0 5px 0 10px" }}
            expires={365}
            enableDeclineButton
            declineButtonStyle={{ color: "var(--orange)", fontSize: "13px", fontWeight: 500, background: "#fff", border: "1px solid var(--orange)", padding: "5px 10px", borderRadius: "5px", marginLeft: "5px" }}
            declineButtonText="Privacy Policy"
            onDecline={() => {
                Cookies.set("dataconnectorcookie", false, { expires: new Date(Date.now() - 1) })
                window.location.href = `${REACT_APP_MARKETING_SITE}/privacy`;
            }}
        >
            Data Connector uses cookies to ensure you get the best experience on our website.
        </CookieConsent>
    </>)
}
