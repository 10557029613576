import { useEffect, useState } from 'react'
import { useLocation } from "react-router-dom"
import { Box, Typography, Divider, FormControlLabel, Checkbox, Button, Snackbar, Slide } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import MaterialUITheme from '../../config/material-ui-theme'
import { UserAPIService } from '../../config/api-service'
import useScript from '../../config/useScript'
import { useAppContext } from '../../context'


const checkList = [
  {
    field: 'MarketingFLG',
    label: 'Marketing (discounts, savings, no spam)'
  },
  {
    field: 'SupportFLG',
    label: 'Support'
  },
  {
    field: 'PipelineFailureFLG',
    label: 'Pipeline Failure'
  },
  {
    field: 'PipelineSuccessFLG',
    label: 'Pipeline Success'
  },
  {
    field: 'AccountNotificationFLG',
    label: 'Account Notification'
  },
  {
    field: 'NewsletterFLG',
    label: 'Newsletter (best practices, new connectors, announcement, etc)'
  },
]

function SlideTransition (props) {
  return <Slide {...props} direction="left" />;
}

export default function UnsubscribeEmail () {
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const email = query.get('email')
  const uid = query.get('uid')

  const [preferences, setPreferences] = useState({})
  const { ip } = useAppContext()

  const getPreferences = async (payload) => {
    try {
      const { data } = await UserAPIService.getEmailPreference(payload)
      if (data && Object.keys(data).length) {
        setPreferences(data)
      }
    } catch { }
  }

  useEffect(() => {
    email && uid && getPreferences({ email, uid })
  }, [email, uid])

  useScript(ip)

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    variant: ''
  })
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setSnackbar({
      ...snackbar,
      open: false
    })
  }

  const handleOnChange = async (field, label, e) => {
    const isChecked = e.target.checked
    setPreferences({
      ...preferences,
      [field]: isChecked ? 1 : 0
    })
  }

  const handleSubmit = async () => {
    try {
      await UserAPIService.updateEmailPreference({
        data: {
          ...preferences,
          EmailTokenTxt: uid
        },
        email
      })
      getPreferences({ email, uid })
      setSnackbar({
        open: true,
        message: 'Saved changes successfully',
        variant: 'success'
      })
    } catch {
      setSnackbar({
        open: true,
        message: 'Something went wrong',
        variant: 'error'
      })
    }
  }

  return (
    <MaterialUITheme>
      <Box
        sx={{
          width: 1200,
          maxWidth: '100%',
          margin: '5vh auto',
          textAlign: 'left',
          '& .check-list-item': { width: '100%', marginBottom: 16 },
          '& .check-list-item svg': { fontSize: 28 },
          '& .check-list-item > span:last-child': { fontSize: 24 }
        }}
      >
        <Typography component={'h2'} variant="h3" style={{ textAlign: 'center', marginBottom: 4 }}>
          Email Settings
        </Typography>

        <Divider style={{ marginBottom: 8 }} />

        {
          preferences && !!Object.keys(preferences).length && checkList.map(({ field, label }) => (
            <FormControlLabel className="check-list-item" key={field} control={<Checkbox checked={!!preferences[field]} onChange={(e) => handleOnChange(field, label, e)} />} label={label} />
          ))
        }

        {
          preferences && !!Object.keys(preferences).length
            ? <Button variant="contained" color="primary" size="large" style={{ marginTop: 20 }} onClick={handleSubmit}>Save Changes</Button>
            : <p style={{ textAlign: 'center' }}>No email settings found</p>
        }

        <Snackbar
          key={snackbar.message}
          open={snackbar.open}
          onClose={handleCloseSnackbar}
          TransitionComponent={SlideTransition}
          autoHideDuration={5000}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <MuiAlert elevation={6} variant="filled" onClose={handleCloseSnackbar} severity={snackbar.variant}>
            {snackbar.message}
          </MuiAlert>
        </Snackbar>
      </Box>
    </MaterialUITheme>
  )
}