import { Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React from 'react';
import { Cookies } from "react-cookie-consent";
import { UserAPIService } from '../../../config/api-service';
import useCaptureEvent from '../../../config/useCaptureEvent';
import { useAppContext } from '../../../context';

const { REACT_APP_URL_ANONYMOUS } = process.env;

const FreeTrialEmailInput = () => {
    // const [email, setEmail] = useState("");
    // const emailReg = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+.[a-zA-Z0-9+.]$/;
    const { ip } = useAppContext()
    const captureEvent = useCaptureEvent()

    // let history = useHistory();

    const theme = useTheme()
    const md = useMediaQuery(theme.breakpoints.up('md'))

    const handleSubmit = async () => {
        if (!parseInt(Cookies.get('anonymous'))) {
            const { data } = await UserAPIService.anonymousRegister({ timezone: Intl.DateTimeFormat().resolvedOptions().timeZone });
            if (data.success) {
                window.location.assign(REACT_APP_URL_ANONYMOUS);
                captureEvent(ip, "Try Now", "Success");
            }
            else{
                captureEvent(ip, "Try Now", data?.message);
            }
        }
        window.location.assign(REACT_APP_URL_ANONYMOUS);
        captureEvent(ip, "Try Now", "Clicked");
    }

    // const handleSubmit = async () => {
    //     const { data } = await UserAPIService.anonymousRegister({ timezone: Intl.DateTimeFormat().resolvedOptions().timeZone })
    //     // data.newUser ? history.push("/login-setup", { email }) : history.push({
    //     //     pathname: '/login',
    //     //     // search: `?email=${email}`,
    //     //     state: { email }
    //     // })
    //     if (data.success) {
    //         window.location.assign(REACT_APP_URL_ANONYMOUS);
    //     }
    //     // window.location.assign(REACT_APP_URL_ANONYMOUS);
    //     captureEvent(ip, "Free Trial", "Clicked Start free trial button");
    // }

    return (
        <div style={{ display: md && "flex", marginTop: "40px", justifyContent: "center" }}>
            {/* <TextField
                fullWidth
                onBlur={() => captureEvent(ip, "Free Trial", `Entered ${email}`)}
                style={{ margin: "2px", backgroundColor: "#fff", height: "40px" }}
                variant="outlined"
                size="small"
                type="text"
                value={email}
                name="email"
                placeholder="Enter your email address"
                onChange={(e) => setEmail(e.target.value)}
                error={!emailReg.test(email) && email !== ""}
                label={!emailReg.test(email) && email !== "" ? "Invalid Email!" : ""}
            /> */}
            {/* <div style={{ display: "flex", flexDirection: "column" }}>
                <p onClick={handleSubmit} className="btn btn-primary" style={{ display: 'inline-block', padding: "7px", borderRadius: "3px", minWidth: "130px", margin: "2px", pointerEvents: !emailReg.test(email) && "none" }}>Start free trial</p>
                <Typography style={{ margin: 0, fontSize: "13px", textAlign: "left", marginLeft: "5px" }}>No credit card required<br />14-Day free trial</Typography>
            </div> */}
            <div style={{ display: "flex", flexDirection: "column" }}>
                <p onClick={handleSubmit} className="btn btn-primary" style={{ display: 'inline-block', padding: "7px", borderRadius: "3px", minWidth: "130px", margin: "2px" }}>Try Now</p>
                <Typography style={{ margin: 0, fontSize: "13px", textAlign: "left", marginLeft: "5px" }}>No credit card required<br />14-Day free trial</Typography>
            </div>
        </div>
    );
};

export default FreeTrialEmailInput;