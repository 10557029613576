import { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { TextField, Button, Popper } from '@material-ui/core'
import PasswordChecklist from "react-password-checklist"
import RaisedFormComponent from '../utils/raised-form'
import { useAlertContext } from '../../context/alert'
import { UserAPIService } from '../../config/api-service'
import { handleError } from '../../config/api-response'
import useScript from '../../config/useScript'
import { useAppContext } from '../../context'
const { REACT_APP_URL } = process.env;

export default function ChangeSystemPassword() {

    const { state: { EmailTXT = '', PasswordHASH = '' } = {} } = useLocation()

    const [password, setPassword] = useState("")
    const [formSubmitted, setFormSubmitted] = useState(false)
    const [verifyPassword, setVerifyPassword] = useState("")
    const [formErrorMessage, setFormErrorMessage] = useState("")
    const [anchorEl, setAnchorEl] = useState(null)
    const [passwordValid, setPasswordValid] = useState(false)
    const [termsChecked, setTermsChecked] = useState(false);

    const { setAlertMessage, setSeverity, setOpen, setAlertTitle } = useAlertContext()
    const { ip } = useAppContext()

    const mediaMatch = window.matchMedia('(max-width: 500px)')
    const open = Boolean(anchorEl)

    const history = useHistory()

    const handleSubmit = (event) => {
        event.preventDefault()
        if (!passwordValid) {
            setFormErrorMessage('Password did not meet the requirements.')
            return
        }
        if (!termsChecked) {
            setFormErrorMessage('Accept Terms of Use and Privacy Statement to continue.')
            return
        }
        try {
            UserAPIService.updateTemporaryPassword({ NewPasswordHASH: password, termsPrivacyAgreement: termsChecked, EmailTXT, PasswordHASH })
                .then(json => {
                    if (json) {
                        setFormErrorMessage('')
                        setFormSubmitted(true)
                        setSeverity("success")
                        setAlertTitle("")
                        setAlertMessage(json.data.message)
                        setOpen(true)
                        UserAPIService.login({ EmailTXT, PasswordHASH: password })
                            .then(json => {
                                if (json.status === 200) {
                                    window.location.assign(REACT_APP_URL)
                                } else {
                                    if (json.status === 404) {
                                        throw Error("Looks like there has been an error on our end. Sorry! (Error code 404)")
                                    } else if (json.status === 401) {
                                    }
                                }
                            })
                            .catch(err => { });
                    }
                })
        } catch (err) { handleError(err, "Form", setFormErrorMessage) }
    }

    useScript(ip)

    useEffect(() => {
        if (termsChecked) {
            setFormErrorMessage("")
        }
    }, [termsChecked])

    useEffect(() => {
        if (passwordValid) {
            setFormErrorMessage("")
        }
    }, [passwordValid])

    return (
        <RaisedFormComponent pageLabel="Reset Password">
            <form noValidate onSubmit={handleSubmit} >
                <Popper open={open} anchorEl={anchorEl} placement="top-end" style={{ zIndex: 10000, marginBottom: 5 }}>
                    <div style={{ border: '1px solid #ccc', backgroundColor: '#fff', padding: 5 }}>
                        <PasswordChecklist
                            rules={["minLength", "number", "match"]}
                            minLength={6}
                            value={password}
                            valueAgain={verifyPassword}
                            onChange={(isValid) => setPasswordValid(isValid)}
                            iconSize={mediaMatch ? 10 : 12}
                            className={mediaMatch.matches ? "size-10" : "size-12"}
                            messages={{minLength:"Password has at least 6 characters"}}
                        />
                    </div>
                </Popper>

                <div className="margin-2 not-sides">
                    <TextField
                        size="small"
                        className="field"
                        id="username"
                        name="username"
                        label="New Password"
                        variant="outlined"
                        type="password"
                        value={password}
                        error={!password && formSubmitted}
                        onFocus={(event) => setAnchorEl(anchorEl ? null : event.currentTarget)}
                        onBlur={(event) => setAnchorEl(anchorEl ? null : event.currentTarget)}
                        onChange={e => setPassword(e.target.value)} />
                </div>
                <TextField
                    size="small"
                    className="field"
                    id="username"
                    name="username"
                    label="Verify Password"
                    variant="outlined"
                    type="password"
                    value={verifyPassword}
                    error={!verifyPassword && formSubmitted}
                    onFocus={(event) => setAnchorEl(anchorEl ? null : event.currentTarget)}
                    onBlur={(event) => setAnchorEl(anchorEl ? null : event.currentTarget)}
                    onChange={e => setVerifyPassword(e.target.value)}
                />
                <div className="error text margin-1">{formErrorMessage}</div>
                <div style={{ margin: "20px 0", textAlign: "left", fontSize: "15px" }}>
                    <input type="checkbox" id="terms" name="terms" checked={termsChecked} onChange={(e) => setTermsChecked(e.target.checked)} />
                    <label htmlFor="terms"> I've read and agree to the <a href="/terms" target="_blank" className='link-txt'>Terms of Use</a> and <a href="/privacy" target="_blank" className='link-txt'> Privacy Statement</a></label>
                </div>
                <div className="row">
                    <Button fullWidth={true} type="submit" variant="contained" className="btn btn-primary submit align-center">Submit</Button>
                </div>
            </form>
        </RaisedFormComponent >
    )
}
